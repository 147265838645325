import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { FooterRecCard } from "../components/resources"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { v4 as uuidv4 } from "uuid"
import Dar from "@mui/icons-material/DoubleArrow"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/risk-reduction/",
    label: "RISK REDUCTION",
  },
]

const resourcesList = [
  {
    LinkUr: "https://sp1.sdcdn.app/pdf/MDR-Solution-Brief.pdf",
    subtitle: "Managed Detection and Response",
    cat: "SOLUTION BRIEF",
    title: "VigilantMDR",
  },
  {
    title: "VigilantMNDR",
    subtitle: "Managed Network Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MNDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "Vigilant365™ ",
    subtitle: "Enhanced Microsoft 365 Detection",
    LinkUr: "https://sp1.sdcdn.app/pdf/V365-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
]

const RiskReduction = () => (
  <Layout>
    <Seo title="RISK REUDCTION" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>

    <div className="grid--bg--for-it-ops-rr">
      <section className="section-title-top--platform ">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h1>RISK REDUCTION</h1>
              <h4>ATTACK SURFACE REDUCTION</h4>
              <p className="content">
                Risk reduction is not a destination. It is not about installing
                a product and forgetting. It has to constantly adapt and it is
                personal to every company. That is why an engaged partner like
                Vigilant is necessary.
              </p>
              <Link to="/contact/" className="nav-section--cta-btn">
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    reduce risk today
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
            <div className="section-col-2"></div>
          </div>
        </div>
      </section>
    </div>

    <section className="section-overview">
      <div className="container">
        <br />
        <div className="row pb-5">
          <div className="col-sm-12">
            <div className="section-col-rr">
              <h4>Be the First to Discover Any Weakness </h4>
              <hr />
            </div>

            <div className="row">
              <div className="col-6">
                <p>
                  An organization’s structure can resemble that of a museum,
                  with new exhibits and additional sections added over time.
                  Each wing of an organization has its own entrances, exits and
                  thus security vulnerabilities. And like a museum, an
                  organization’s attack surface is a public space, open for
                  others to explore and test.
                </p>
                <p>
                  Each of those potential security vulnerabilities not only
                  needs to be checked by someone who knows the landscape, but
                  re-checked to make sure that the entire attack surface is
                  secure.
                </p>
                <p>
                  In the dark world of bad cyber actors, be they nation states
                  or independent criminal networks, there are user handbooks on
                  common defenses and thus vulnerabilities for companies like
                  yours: Exploit Kits, Malware Kits, etc. Vigilant has those
                  handbooks too, but we use them to protect and defend.
                </p>
              </div>
              <div className="col-6">
                <p>
                  Vigilant Managed Vulnerability Detection and Response exists
                  to use those handbooks – alongside our tradecraft and
                  expertise – to reduce an organization’s attack surface: from
                  patched vulnerabilities, password management, and open text
                  passwords, to two and multi-step authentications. We map all
                  vulnerabilities, rank them, create a game plan, patch, and
                  remediate and then we test and retest, after having ingested
                  any new knowledge about a company’s attack surface.
                </p>
                <p>
                  Additionally, we have benevolent hackers (Penetration Testers)
                  on our team. And they are as shrewd and cunning as any bad
                  actor, but their valiant mission is to be the first to
                  discover any weakness, so we can patch and protect.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section-box-cta">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <div className="section-col-1--content">
              <h2>
                Do You Have Weaknesses in Your Defense <br /> Perimeter?
              </h2>
              <hr />
              <Link
                to="/attack-surface-reduction"
                className="nav-section--cta-btn"
              >
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    learn more
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section-platform ">
      <div className="container">
        <div className="row">
          <div className="col-sm-7">
            <div className="side-binder-r">
              <h6>BLOG</h6>
              <h3>
                DIFFERENCE BETWEEN VULNERABILITY SCANNING AND PENETRATION
                TESTING
              </h3>
            </div>

            <p className="content">
              Learn the difference between Vulnerability Scanning, Penetration
              Testing and Red Teaming while learning which is best for your
              company.
            </p>
            <div className="ctizzle">
              <Link
                to="/blog-pages/difference/"
                className="nav-section--cta-btn"
              >
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    read more
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
          </div>
          <div className="col-sm-5">
            <StaticImage
              src="../images/security-maturity/hexRR.png"
              quality={95}
              formats={["AUTO", "WEBP"]}
              alt="Service Placeholder"
            />
          </div>
        </div>
      </div>
    </section>
    <section className="section-resources">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h3>RESOURCES</h3>
          </div>
          <div className="section-col-2">
            <ul className="resources-list">
              {resourcesList.map(data => {
                return (
                  <FooterRecCard
                    altTxt={data.title}
                    title={data.title}
                    subTitle={data.subtitle}
                    recCat={data.cat}
                    LinkUrl={data.LinkUr}
                    key={uuidv4()}
                  />
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default RiskReduction
